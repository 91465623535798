export default {
    email: [{ required: true}, { pattern: /^(.+)@(.+){2,}\.(.+){2,}$/ }],
    role: [{ required: true}],
    password: [{ required: false},{ minLength: 6 }],
    checkPassword: [{
        errorText: 'Пароли не совпадают',
        customValidator: {
            callback: (value, form) => form.password === value
        }
    }],
}