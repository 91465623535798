<template>
  <b-form>
    <b-row>
      <b-col>
        <b-form-group label="Почта">
          <b-form-input v-model="form.email" placeholder="Почта" :state="formErrors.email"/>
        </b-form-group>
        <b-form-group label="Роль">
          <b-form-select id="gender" v-model="form.role" :state="formErrors.role">
            <b-form-select-option :value="null">Выберите роль</b-form-select-option>
            <b-form-select-option :value="'admin'">Админ</b-form-select-option>
            <b-form-select-option :value="'manager'">Менеджер</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Фамилия">
          <b-form-input v-model="form.lastName" placeholder="Фамилия" :state="formErrors.lastName"/>
        </b-form-group>
        <b-form-group label="Имя">
          <b-form-input v-model="form.name" placeholder="Имя" :state="formErrors.name"/>
        </b-form-group>
        <b-form-group label="Отчество">
          <b-form-input v-model="form.middleName" placeholder="Отчество" :state="formErrors.middleName"/>
        </b-form-group>
        <b-form-group label="Офис">
          <b-form-select v-model="form.stationId">
            <b-form-select-option v-for="station in stations" :value="station.id" :key="station.id">{{ station.title }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Пароль">
          <b-form-input type="password" v-model="form.password" placeholder="Пароль" :state="formErrors.password"/>
        </b-form-group>
        <b-form-group label="Повторите пароль">
          <b-form-input type="password" v-model="form.checkPassword" placeholder="Повторите пароль" :state="formErrors.checkPassword"/>
        </b-form-group>
        <b-form-checkbox v-model="form.isBlocked">
          Заблокирован
        </b-form-checkbox>
      </b-col>
    </b-row>
    <div class="text-right mt-4">
      <b-button :variant="btnVariant" @click="onSave">{{ btnTitle }}</b-button>
    </div>
  </b-form>
</template>

<script>
import validate from "@/utils/formValidator";
import USER from "@/forms/USER";
import {mapState} from "vuex";

const USER_INIT_FORM = () => ({
  email: '',
  role: null,
  name: null,
  lastName: null,
  middleName: null,
  stationId: null,
  password: '',
  checkPassword: '',
  isBlocked: false
})

export default {
  name: "Form",
  props: {
    init: { required: false, default: () => USER_INIT_FORM() },
    btnVariant: { required: true },
    btnTitle: { required: true }
  },
  data() {
    return {
      form: this.init,
      errors: {}
    }
  },
  watch: {
    form: {
      handler: function () {
        this.validateForm();
      },
      deep: true
    }
  },
  computed: {
    formErrors: function () {
      return Object.fromEntries(Object.entries(this.errors).map((item) => [item[0], !item[1]]))
    },
    ...mapState({
      stations: state => state.Stations.items,
    })
  },
  methods: {
    removeAt(idx) {
      this.form.points.splice(idx, 1);
    },
    add() {
      this.form.points.push({
        name: '',
        stopTime: '',
        distanceToNextPoint: '',
        distanceToNextPointKm: '',
        timeToNextPoint: ''
      })
    },
    fillForm(form) {
        const enabledKeys = Object.keys(USER_INIT_FORM())
        this.form = Object.fromEntries(Object.entries(form).filter(([key]) => ['id', ...enabledKeys].includes(key)));
    },
    phoneFormat(value) {
      if (value === '') {
        return ''
      }

      let replacedValue = value.replace(/\D/g, '')
      return ('+' + replacedValue).replace(/\+(\d{1,3})(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1($2)$3-$4-$5')
    },
    resetForm() {
      Object.assign(this.form, USER_INIT_FORM())
      this.errors = {}
    },
    validateForm() {
      this.errors = validate(this.form, USER)
      return Object.keys(this.errors).length === 0
    },
    onSave() {
      console.log(this.errors)
      if (!this.validateForm())
        return

      this.$emit('onSave', {...this.form});
    }
  }
}
</script>

<style scoped>
  .remove-icon-style {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
</style>